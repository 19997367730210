import { Fragment, useState } from 'react'
import { DefaultProps } from '@types'

import Navigation from '../elements/navigation'
import Button from '../elements/button'

import Profile from './profile'

export default interface Properties extends DefaultProps {
  onReturn?: () => void;
}

export default function NavigationBar(props: Properties) {
  const [isThemed, setTheme] = useState(false);

  const isMobile = window.innerWidth < 550
  const { onClick, className, theme } = props

  const isThemeToggled = document.documentElement.classList.contains('light');

  const toggleTheme = (palette: string) => {
    const isThemeToggled = document.documentElement.classList.contains('light');

    if (isThemeToggled) {
      document.documentElement.classList.remove('light');
    }
    document.documentElement.classList.add(palette);

    setTheme(palette === 'light')
  }

  return (
    <Navigation>
      <div className="w-full inline-flex justify-between items-end">
        <a href="/">
          <img
            width='52px'
            style={{
              paddingLeft: 10,
              paddingTop: 18
            }}
            src="assets/imgs/logo.png"
          />
        </a>
        <div className="mr-12 mb-3">
          <a href="#docs" className="text-4xl text-ternary hover:text-primary uppercase tracking-[.03em]">Documentation</a>
        </div>
      </div>
    </Navigation >
  )
}
