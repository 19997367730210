import { DefaultProps } from '@types'

interface Properties extends DefaultProps {
  animate?: boolean;
}

export default function Navigation(props: Properties) {
  return (
    <div
      id="navigation"
      className="z-5 bg-secondary fixed w-full z-2 left-0 top-0 border-b-[3px] border-b-solid border-b-ternary mt-[-10px] px-4 py-5"
    >
      {props.children}
    </div>
  )
}
