import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const markdown = `
## Prize-savings

The concept of prize-savings, relates to a collective group of actors pooling  money together to often generate interest from some sort of passive strategy. Often lending the pooled capital out at a premium, this premium once accrued is either then **a) raffled between the participants** or **b) distributed equally**.

What makes this concept "lossless" is that participants always get **at  minimum, what they contributed**. In game theory this is known as a **positive-sum game**, where nobody loses.

## Pools 

Pools are independent escrows for prize distribution and management, each pool accepts only one type of token. For example some placeholder pools would use Ethereum (ETH), Bitcoin (BTC) and USD Coin (USDC), prizes are denominated in the same token. 

## Tickets 

A taxable ERC20 token that are issued on providing contributions to a pool, tickets fulfill two responsibilities a) to derive the proportion of winnings and b) to represent the underlying collateral token value.

Tickets are represented by color, each relating to the underlying pool token. 

## How it works

To help communicate the concept  without  technical details, let's use a metaphor for each pool **as a plum tree**.

* **The rain** that waters the tree represent the **deposits** to a pool, deposits generate tickets. 
* **The branches** on the tree represent the **buckets** in a pool, which each representing fixed odds to win the prize. If there is 10 buckets, each has a 1 in 10 chance of winning.
* **The seasons** represent the **epochs** (cycles); 
  - **Autumn** is when a pool is **open** for deposits, generating tickets. 
  - **Spring** is when the fruits are unripened, representing the pool's **pending** stage, where tickets can be redeemed for the underlying value
  - **Summer** is when the fruits are harvested representing the **closed** stage, where a winning branch (bucket) is selected with the largest fruits
* **The leaves** represents the ticket **stakes** in each bucket of a pool, rewards to buckets are distributed proportionally to stakes.
* **The sun** that shines on the tree represents the external **strategy** (eg: AAVE lending) to generate interest.
* **The fruit** that grows on each branch represents the **premium** in a pool, once every cycle, one branch (bucket) is randomly rewarded with the largest harvest but regardless every cycle fruits grow on every branch.

At the end of every cycle, when a pool is in the **closed** epoch, the premium is allocated in the following distribution: 

- 70% to a winning bucket
- 20% as a premium to ticket backing
- 10% remainder is deducted as a fee

## Reward Credit

This is an advanced feature for winners to increase their chances using their rewards. While the mechanism is complex, lets first talk about three concepts in lending.

> **Collaterisation**: A proportion of deposit "insurance" provided to secure a loan

> **Annual Percentage Yield (APY)**: The rate at which a deposit increases annually

> **Loan-to-value (LTV)**: The collaterisation value relative to the loan value

Participants in any pool have an option to use their accumulated rewards as a means to leverage their exposure to bucket within a pool. This is achieved by providing a loan to participants, while using their underlying rewards as collateral. Which allows the repayment of the debt through the future rewards of the leverage essentially providing **self-repaying loans**.

Now let's walk through an example of how reward credit works;
 
- Bob **buys 1 ETH** of tickets for a pool.
- Decides to stake his tickets into a bucket.
- Eventually **wins 0.3 ETH** for his stake in that bucket.
- Bob's now combined **total of 1.3 ETH** would increase his odds of winning.
- Bob needs money to pay for his gas but wants to increase his exposure, so decides to use his pool credit.
- Bob **takes a 0.15 ETH loan** (assuming LTV @ 200%) from the pool using his active credit.
- The **0.3 ETH of rewards are converted to tickets** and staked to the bucket which they were earned.  
- The converted ticket balance are then **locked until the 0.15 ETH is repaid**.
- Depending on the APY rate and size of the pool, the time for repayment differs.
- **Loan is repayed from the future rewards** that Bob earns or if Bob decides to **either repay manually** upfront.
- No need for liquidations as the converted ticket balance has a right to future awards.
- Bob can pay his gas now without worry and potentially win more in the next cycle.

**Repayment time in this mechansim is subject to change with the amount of pool deposits and bucket stakes**, there may be scenarios where small credit positions are not feasible to repay in a reasonable time. It is recommended users build up their credit lines, to use them when optimal and prevent unproductive credit positions.

**All interest from credit positions is added as a premium to pool ticket backing.**

## Frequent Questions 

> How is interest generated? 

The strategy to used to collectively grow the deposits in a pool is by supplying an asset to lend to AAVE, which a debtor will pay interest to the pool for access to the loan.

> When can I make a deposit to a pool?

You can deposit to a pool in it's active stage that which occurs weekly.

> When can I redeem my deposit from a pool?

You redeem tickets for deposits in a pool in it's pending stage that which occurs weekly.

> Can I always redeem the initial deposit made to a pool? 

Yes, given that the cycle of a pool is in it's pending stage that which occurs weekly. If you want to redeem a deposit outside the pending stage, **tickets will have to be sold on secondary markets, which incurs a ticket tax**, this tax varies per pool.

> What happens if my bucket debt repayment time becomes to large?

Given that the loan is over-collaterised, users can use a flash loan to repay the position getting access to the remainder of the collateral minus flash loan fees.

> Can I stake to multiple buckets? 

Yes, buckets are simply just fixed odds; 12 buckets, 8.75% probability of winning. Participants can decide their odds through bucket selection.

> How is the winning bucket selected? 

Using [Witnet's decentralised oracle network](https://witnet.io/), we simply request a random number, which has provably safe assumptions. 

> What happens if the oracle fails? 

Each pool has a failsafe mechanisim that can be triggered, this stops the distribution of prizes to buckets but instead distributes all premium proportionally to all participants through ticket backing premium. Allowing the system to continue to function as savings solution.

> Where is the protocol deployed?

Lossless will be deployed on [Base](https://www.base.org/) (for now)
`

const generateId = (children) => {
  const text = typeof children === "string" ? children : children?.join("") || "";
  return text.toLowerCase().replace(/\s+/g, "-").replace(/[^\w-]/g, ""); // Remove special characters
};

function SidebarNavigation({ headings }: { headings: string[] }) {

  const handleScroll = (id) => {
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <aside className="w-64 hidden md:block fixed h-screen overflow-y-auto border-r p-6 bg-secondary border-r-3px border-r-solid border-r-ternary">
      <ul className="space-y-12 mt-28">
        {headings?.map((heading) => (
          <label
            key={heading.id}
            className={`ml-${heading.level === "h1" ? "0" : heading.level === "h2" ? "4" : "6"}`}
          >
            <div
              role="button"
              onClick={() => handleScroll(heading.id)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleScroll(heading.id);
                }
              }}
              tabIndex={0}
              className="block text-3xl tracking-[.05em] text-ternary hover:text-primary cursor-pointer"
            >
              {heading.text}
            </div>
          </label>
        ))}
      </ul>
    </aside>
  );
};

export default function Documentation() {
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    const extractedHeadings = [];
    document.querySelectorAll("h1, h2, h3").forEach((el) => {
      extractedHeadings.push({
        id: el.id,
        text: el.innerText,
        level: el.tagName.toLowerCase(),
      });
    });
    setHeadings(extractedHeadings);
  }, [markdown]); // Recalculate when markdown update

  return (
    <div className="flex">
      <SidebarNavigation headings={headings} />
      <div className="max-w-3xl mx-auto md-ml-94 md-mr-0 py-8 mt-44 px-12 tracking-[.07em] lg:ml-64">
        <ReactMarkdown
          components={{
            h1: ({ children, ...props }) => {
              const id = generateId(children);
              return (
                <a href={`#/docs#${id}`}>
                  <h1 id={id} className="text-6xl uppercase font-bold mb-6 mt-8" {...props}>
                    {children}
                  </h1>
                </a>
              );
            },
            h2: ({ children, ...props }) => {
              const id = generateId(children);
              return (
                <a href={`#/docs#${id}`}>
                  <h2 id={id} className="text-5xl uppercase font-bold mb-4 mt-6" {...props}>
                    {children}
                  </h2>
                </a>
              );
            },
            h3: ({ children, ...props }) => {
              const id = generateId(children);
              return (
                <h3 id={id} className="text-4xl uppercase font-bold mb-3 mt-5" {...props}>
                  {children}
                </h3>
              )
            },
            a: ({ href, children, ...props }) => {
              const isExternal = href.startsWith("http"); // Check if link is external
              return (
                <a
                  href={href}
                  target={isExternal ? "_blank" : "_self"} // Open external links in new tab
                  rel={isExternal ? "noopener noreferrer" : ""}
                  className="text-white hover:underline"
                  {...props}
                >
                  {children}
                </a>
              );
            },
            p: ({ node, ...props }) => (
              <p className="text-3xl leading-relaxed" {...props} />
            ),
            blockquote: ({ node, ...props }) => (
              <blockquote className="border-l-ternary border-l-solid border-l-[4px] bg-[#ffc430] pl-10 font-italic" {...props} />
            ),
            strong: ({ node, ...props }) => (
              <strong className="font-bold [-webkit-text-stroke:0.5px_black]" {...props} />
            ),
            ul: ({ node, ...props }) => (
              <ul className="list-disc pl-6 text-3xl mb-4 space-y-2" {...props} />
            ),
            ol: ({ node, ...props }) => (
              <ol className="list-decimal text-3xl pl-6 mb-4 space-y-2" {...props} />
            ),
            code: ({ node, inline, ...props }) => (
              inline ?
                <code className="bg-gray-100 px-1 rounded font-mono text-sm" {...props} /> :
                <code className="block bg-gray-100 p-4 rounded-lg font-mono text-sm mb-4" {...props} />
            ),
            table: ({ node, ...props }) => (
              <div className="overflow-x-auto mb-4">
                <table className="min-w-full divide-y divide-gray-200" {...props} />
              </div>
            ),
            thead: ({ node, ...props }) => (
              <thead className="bg-gray-50" {...props} />
            ),
            tbody: ({ node, ...props }) => (
              <tbody className="divide-y divide-gray-200 bg-white" {...props} />
            ),
            tr: ({ node, ...props }) => (
              <tr className="hover:bg-gray-50" {...props} />
            ),
            th: ({ node, ...props }) => (
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...props} />
            ),
            td: ({ node, ...props }) => (
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" {...props} />
            )
          }}
        >
          {markdown}
        </ReactMarkdown>
      </div>
    </div>
  );

}
