// @ts-ignore
// @ts-nocheck
import ReactDOM from 'react-dom/client';
import React, { Suspense, Fragment, useState, lazy } from 'react'
import { WagmiConfig as WagmiProvider } from 'wagmi'
import { createConfig, mainnet } from 'wagmi'
import { createPublicClient, http } from 'viem'
import { RouterProvider, createHashRouter } from "react-router-dom";

import NavigationBar from './components/navbar'
import FootBar from './components/footbar'
import Landing from './views/landing'
import Documentation from './views/documentation'

import 'uno.css';
import './styles/index.css';

const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: mainnet,
    transport: http()
  })
})

function App() {

  const suspenseWrap = (route: JSX.Element) => (
    <Suspense fallback={<></>}>{route}</Suspense>
  )

  const router = createHashRouter([
    {
      path: "/",
      element: <Landing />
    },
    {
      path: "docs",
      element: <Documentation />
    }
  ])

  return (
    <WagmiProvider config={config}>
      <NavigationBar />
      <RouterProvider router={router} />
    </WagmiProvider>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />)
