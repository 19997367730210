export default function Landing() {
  return (
    <div className="h-screen w-full flex flex-row items-center align-center justify-center">
      <div className="flex flex-col gap-24 md:gap-12 md:mt-12">
        <label className="ml-8 text-6xl uppercase text-[#a76c05] tracking-[.02em] md:hidden">
          No losses
        </label>
        <div className="block m-auto">
          <img
            src="assets/imgs/tickets.png"
            className="h-[120px] md:h-[87.5px]"
          />
        </div>
        <label className="ml-8 text-5xl uppercase text-[#a76c05] tracking-[.02em] hidden md:block">
          No losses, only wins <span className="tracking-[.06em]">...</span>
        </label>
        <label className="ml-8 text-6xl uppercase text-[#a76c05] tracking-[.02em] md:hidden">
          only wins <span className="tracking-[.06em]">...</span>
        </label>
      </div>
      <a
        target="_blank"
        href="https://twitter.com/losslessdotfun"
        className="absolute bottom-5 md:right-10 text-5xl text-[#ffe196] hidden md:block"
      >
        @losslessdotfun
      </a>
    </div>
  )
}
